import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";

const LightboxContainer = {
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
    gridGap: "5px",
};

const PreviewButton = {
    background: "transparent",
    border: "none",
    padding: 0,
    margin: 0,
};

class Lightbox extends React.Component {
    static propTypes = {
        productImages: PropTypes.array.isRequired,
    };
    constructor(props) {
        super(props);
        this.state = { showLightBox: false, selectedImage: null };
    }

    render() {
        const { productImages } = this.props;
        const { selectedImage, showLightbox } = this.state;
        return (
            <>
                <div style={LightboxContainer}>
                    {productImages.map((image, index) => (
                        <button
                            style={PreviewButton}
                            key={index}
                            type={"button"}
                            onClick={() =>
                                this.setState({
                                    showLightbox: true,
                                    selectedImage: image,
                                })
                            }
                        >
                            <Img
                                fluid={image.childImageSharp.fluid}
                                title={`Instalasi di ${image.base}`}
                                alt={`Gambar instalasi di ${image.base}`}
                            />
                        </button>
                    ))}
                </div>
                {showLightbox && (
                    <Modal
                        size={"lg"}
                        backdrop={"static"}
                        centered={true}
                        show={showLightbox}
                        onHide={() => this.setState({ showLightbox: false })}
                    >
                        <ModalHeader closeButton>
                            {selectedImage.base}
                        </ModalHeader>
                        <ModalBody>
                            <Image
                                fluid={true}
                                src={
                                    selectedImage.childImageSharp.fluid
                                        .originalImg
                                }
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button type={"button"} disabled={true}>
                                Sebelum
                            </Button>
                            <Button type={"button"} disabled={true}>
                                Berikut
                            </Button>
                        </ModalFooter>
                    </Modal>
                )}
            </>
        );
    }
}
export default Lightbox;
